export default {
  landingPageTitle: "Wait ah...",
  landingPageSubtitle: "Better job opportunities are on the way",
  iAm: "I am",
  registerInterest: "Register Interest",
  aJobSeeker: "a Jobseeker",
  anEmployer: "an Employer",
  submissionSuccess: "Your submission was successful!",
  invalidEmail: "Email is invalid!",
  somethingWentWrong: "Oops, something went wrong! Please try again later.",
  somethingWentWrongSubtitle: "Maybe you can find what you need below?",
  selectPlaceholder: "Select",
  promptSelectIdentity: "Please select your identity",
  until: "until",
  isLaunched: "is launched!",
  email: "Email",
  success: "Success",
  error: "Error",
  remindMe: "Remind Me",

  // nav bar
  login: "Login",
  signup: "Sign Up",
  profile: "Profile",
  logout: "Logout",
  jobs: "Jobs",
  companies: "Companies",
  forEmployer: "For Employer",
  home: "Home",
  startHiring: "Start hiring today",

  // homepage
  homepageTagline: "UPGRADE GAJI, UPGRADE HIDUP.",
  homepageSearchText: "What are you looking for?",
  homepageSearchButtonText: "Discover Now",
  homepageTrendingTitleText: "Trending Jobs",
  homepageTrendingJobButtonText: "Discover More",

  // job card
  applyButtonText: "Apply",
  appliedButtonText: "Applied",
  applyJob: "Successfully applied job!",

  // footer
  contactUs: "Contact Us",
  faq: "FAQ",
  copyright: "© Copyright",
  communityGuidelines: "Community Guidelines",
  privacyPolicy: "Privacy Policy",
  termsAndCondition: "Terms & Conditions",
  companies: "Companies",
  more: "More",
  close: "Close",
  footerIntro:
    "NaikGaji, founded in 2023, is committed to empowering the backbone of industries in Malaysia by providing a non-executive job platform that matches their aspirations.",

  // jobs page
  specialisationFilter: "Job Category",
  statesFilter: "States",
  jobTypeFilter: "Job Types",
  salaryFilter: "Salary",
  search: "Search",
  experienceLevelFilter: "Experience Level",
  clearFilter: "Clear All",
  applyFilter: "Apply Filter",
  showFilters: "Show Filters",
  hideFilters: "Hide Filters",
  searchAllFields: "Search Location, Job Title, Company Name",

  // companies page
  industry: "Industry",

  // company card
  jobs: "Jobs",

  // contact us page
  contactUsTitleText: "We'd love to hear from you",
  contactUsFormBoxPlaceholder:
    "Let us know if you have any questions, feedback or suggestions! We will get back to you within 1 working day.",
  submit: "Submit",

  // single jobs page
  visitCompanyProfile: "Visit Company Profile",

  // employer page
  hireBestTalentCopy: "Hire talent like the best companies in Malaysia",

  // onboarding section
  personalInformation: "Personal Information",
  workExperience: "Work Experience",
  education: "Education",
  skillsAndLanguage: "Skills & Languages",
  resumeUpload: "Resume Upload",
  selectAnOption: "Select an Option",

  // onboarding
  optional: "Optional",
  basicInfo: "Basic Information",
  fullName: "Full Name",
  gender: "Gender",
  birthDate: "Birth date",
  nationality: "Nationality",
  mobileNumber: "Phone number",
  preferredLocation: "Preferred Location",
  back: "Back",
  next: "Continue",
  educationBg: "Education background",
  highestEduLvl: "Highest Education Level",
  gradYear: "Graduation Year",
  eduInstitute: "Education Institution",
  fieldOfStudy: "Field of Study",
  major: "Major",
  uploadResume: "Upload Resume",
  dropOrBrowse: "Drag and drop your resume here or",
  chooseFile: "Choose file",
  acceptFormat: "Accepted formats: .pdf, .doc, .docx",
  fileSizeLimit: "File size limit",
  uploadNewFile: "Upload new file",
  upload: "Upload",
  browse: "Browse",
  supportFileType: "Support file type: .pdf, .doc, .docx",
  skip: "Skip",
  done: "Done",
  save: "Save",
  cancel: "Cancel",
  delete: "Delete",
  remove: "Remove",
  add: "Add",
  edit: "Edit",
  discardChange: "Discard changes",
  leaveWithoutSaving: "Are you sure you want to leave without saving?",
  shortSummary: "Short Summary",
  state: "State",
  city: "City",
  shoppingMalls: "Shopping Malls",
  areYouSureDelete: "Are you sure you want to delete",

  haveWorkExperience: "I have work experience",
  includingFullTime:
    "Including full-time, part-time, internships, freelancing etc.",
  noWorkExperience: "I have no work experience",
  iAmStudent: "I am a student/fresh graduate, and looking for my first job.",

  addWorkExperience: "Add work experience",
  jobTitle: "Job title",
  companyName: "Company name",
  currentlyWorking: "I’m currently working in this role",
  scrollDownToAdd: "scroll down to add up to 3 experiences",
  startDate: "Start date",
  endDate: "End Date",
  jobDescription: "Job description",
  optional: "optional",
  pleaseSelect: "Please select",
  requiredField: "Required field",
  removeSection: "Remove Section?",
  youCannotUndo: "You cannot undo this action.",
  removed: "Removed",
  thisSectionRemoved: "This section has been removed",

  addEducation: "Add education",
  skipForNow: "Skip for now",

  location: "Location",
  skills: "Skills",
  language: "Language",
  highlightYourSkillsLang:
    "Highlights your skills and languages to stand out to employers",
  highlightYourSkillsOnly: "Highlights your skills to stand out to employers",
  addMoreSkillsLater: "You may add more skills in your user profile later",
  searchSkills: "Search skills",
  searchLanguages: "Search languages",
  addMoreLangLater: "You may add more languages in your user profile later",
  maxLimitReachedRemove: "Max limit reached, remove an input and add another",
  seeMore: "See more",
  seeLess: "See less",
  present: "Present",
  brieflyDescribeYourself:
    "Briefly describe yourself and showcase your skills and experiences to employers",

  // Onboarding Modal
  completeYourProfile: "Complete your profile",
  almostThereCompleteProfile:
    "Almost there! Please complete your profile to apply and increase your chances of getting hired.",
  readyToApply: "Ready to Apply?",
  yourProfileLooksGreat:
    " Your profile looks great! 🎉 Take a moment to review your details. This will help ensure a smooth application process.",
  youCanEditLater: "Don't worry, you can always edit your profile later.",
  noteEditProfile:
    "Note: Each time you edit your profile, the changes will reflect to all your applications.",

  // snackbar
  saveProfileSuccess: "Save profile success!",
  genderIsRequired: "Gender is required!",
  educationSaved: "Education saved!",
  educationCannotSave: "Education could not be saved, please try again later.",
  fileFormatWrong: "File should be in .doc/.docx/.pdf format",
  resumeUploadSuccess: "Upload resume success!",
  nameIsRequired: "Name is required!",
  phoneNumberRequired: "Phone number is required!",
  emailIsRequired: "Email is required!",
  passwordRequired: "Password is required!",
  confirmPassword: "Please confirm your password",
  birthdateRequired: "Birth date is required!",
  nationalityRequired: "Nationality is required!",
  errorSaving: "Error saving information, please try again later.",
  eduLvlRequired: "Highest education level required.",
  gradYearRequired: "Graduation year required.",
  eduInstituteRequired: "Education Institute required.",
  imageFileFormatWrong:
    "Please ensure that the uploaded file is in png/jpg/jpeg format.",
  couldNotUploadImage: "Could not upload your image, please try again later",
  uploadImageSuccess: "Image upload success",
  passwordDontMatch: "Password and Confirm Password does not match!",
  termsAndConditionRequired: "You must accept the terms and conditions",
  workExperienceRequired:
    "You must have at least one work experience in your profile to make job applications.",
  educationRequired:
    "You must have at least one education background in your profile to make job applications.",
  languageAlreadyExist: "Language already selected or invalid input.",

  // profile
  yourResume: "Your Resume",
  viewResume: "View Resume",
  downloadResume: "Download Resume",
  uploadNewPhoto: "Upload new photo",

  // login
  emailAddress: "Email Address",
  password: "Password",
  confirmPassword: "Confirm Password",
  dontHaveAcc: "Don't have an account?",
  forgotPassword: "Forgot password?",
  emailCantBlank: "Email cannot be blank.",
  passwordCantBlank: "Password cannot be blank.",
  errorLogin: "Error while logging in. Please try again later.",
  loginSuccess: "Login Success",

  // sign up
  signup: "Sign up",
  alreadyHaveAcc: "Already have an account?",
  accExist: "Account already exist, please log instead",
  signupSuccess: "Sign up success",
  signupFail: "Sign up failed",
  pleaseConfirmPassword: "Please confirm your password.",
  policyAcceptance: "By signing up, I have read and agreed to NaikGaji’s",
  policyAcceptanceFb: "By signing-up with the above, you agree to NaikGaji’s",
  privacyPolicy: "Privacy Policy",
  and: "and",
  termsAndCondition: "Terms and Conditions",

  // reset password
  ifEmailExist:
    "If this email has an account on NaikGaji, a reset link will be sent to the email address",
  errorAndTryAgain: "Error! Please try again later.",
  resetPassword: "Reset Password",

  // single job page
  jobDescription: "Job Description",
  jobRequirement: "Job Requirement",
  companyProfile: "Company Profile",

  // company profile
  ourStory: "Our Story",
  ourValue: "Our Value",
  ourBenefits: "Our Benefits",
  ourOffice: "Our Office",
  ourJobs: "Our Jobs",
  ourAddress: "Our Address",

  // Reset password
  setYourPassword: "Set your new password",
  backToLogin: "Back to Login",

  // privacy popup
  privacyPopupTitle: "We’ve updated our Privacy Policy",
  privacyPopupContent:
    "Your privacy matters to us. We’ve updated our Privacy Policy to ensure your experience is secure. Please review and accept our latest policies and terms to proceed.",
  viewOurPolicy: "View Our Policy",
  accept: "Accept",
};

const Color = {
  // Primary colours
  white: "#ffffff",
  black: "#222222",
  naikGreen: "#20C472",
  naikError: "#f75443",
  taglineGreen: "#157646",
  magenta: "#C42072",

  // light colours
  lightGreen: "#E9F9F1",
  lightNgGreen: "rgba(32, 196, 114, 0.2)",
  creamYellow: "#FCF4D4",

  // State Colours
  error: "#F75443",
  success: "#91DC7C",
  blue: "#2020C4",
  reject: "#A81300",
  warn: "#F2A647",

  // Grey Colours
  backgroundGrey: "#F5F5F5",
  lightGrey: "#EFEFEF",
  darkGrey: "#707070",
  secondaryGrey: "#AEAEAE",
  grey: "#D8D8D8",
  lightBlack: "#00000038",
};

export default Color;
